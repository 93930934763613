//@flow
import styles from "./DayItem.scss";

import React, { Component } from "react";
import classnames from "classnames";

import Typography from "@material-ui/core/Typography";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import CalendarIcon from "../../Icons";
import MenuItem from "@material-ui/core/MenuItem";
import ContextMenu from "./ContextMenu";
import Divider from "@material-ui/core/Divider";
import { isTablet, isMobile } from "react-device-detect";
import { getColor } from "../../../../../utils/VenueColors";
import { type Profile } from "../../../redux/modules/user.d";

type Props = {
    user: Profile,
    venue: *,
    id: string,
    event: *,
    collapsedVenues: string[],
    activeEvent: ?{
        event: string,
        series: string,
    },
    clearActiveEvent: () => void,
    activateEvent: (eventUuid: string, seriesUuid: string) => void,
    toggleActiveEvent: (eventUuid: string, seriesUuid: string) => void,
    isShowCanceled: boolean,
    onConfirmOptions: (uuid: string) => void,
    onEditOption: (uuid: string) => void,
    onCancelOption: (uuid: string) => void,
    onEditSeries: (uuid: string) => void,
    onEditEvent: (uuid: string) => void,
    onMoveEvent: (uuid: string) => void,
    onEditConfirmedOption: (uuid: string) => void,
    onDefineEvents: (uuid: string) => void,
    onCancelEvent: (uuid: string) => void,
    onCancelConfirmedOption: (uuid: string) => void,
    onAddOptionToSeries: (uuid: string, date: string) => void,
    onAddEventToSeries: (uuid: string, date: string) => void,
    onShowHistory: (uuid: string) => void,
    onDoubleClickEvent: (uuid: string) => void,
    canUpdate: boolean,
    translate: *,
};

type State = {
    contextMenuPosition: *,
};

export default class MenuTrigger extends Component<Props, State> {
    state = {
        contextMenuPosition: null,
    };

    get label() {
        const { event } = this.props;

        if (event) {
            let label = `${event.seriesName || ""}${
                event.seriesName && event.seriesOptionCode ? " - " : ""
            }${event.seriesOptionCode || ""}`;

            if (event.seriesName === event.seriesOptionCode) {
                label = event.seriesName;
            }

            if (
                event.name &&
                event.name !== event.seriesName &&
                event.name !== event.seriesOptionCode
            ) {
                label = `${event.name} - ${label}`;
            }

            if (event.internal) {
                label = `${label} (${event.internal})`;
            }

            return label;
        }

        return "";
    }

    getStatus(event: *) {
        return event.status === "CANCELED" && event.optionDate !== null
            ? "OPTION_CANCELED"
            : event.status;
    }

    isAllowedToMove() {
        const { user } = this.props;
        return (
            user.authorities.includes("ROLE_ADMINISTRATOR") ||
            user.authorities.includes("ROLE_EVENT_MANAGER")
        );
    }

    /**
     * Render
     */
    render() {
        const {
            event,
            venue,
            collapsedVenues,
            id,
            activateEvent,
            activeEvent,
            clearActiveEvent,
            toggleActiveEvent,
            isShowCanceled,
            canUpdate,
            translate,
            onConfirmOptions,
            onEditOption,
            onCancelOption,
            onCancelConfirmedOption,
            onCancelEvent,
            onDefineEvents,
            onEditConfirmedOption,
            onEditEvent,
            onMoveEvent,
            onEditSeries,
            onAddEventToSeries,
            onAddOptionToSeries,
            onShowHistory,
            onDoubleClickEvent,
        } = this.props;

        const { contextMenuPosition } = this.state;

        /*eslint react/jsx-key:0*/
        return (
            <div
                onContextMenu={e => {
                    e.preventDefault();
                    activateEvent(event.uuid, event.seriesUuid);
                    this.setState({
                        contextMenuPosition: {
                            mouseX: e.clientX,
                            mouseY: e.clientY,
                        },
                    });
                }}
                id={`${id}-${event.uuid}`}
                key={event.uuid}
                tabIndex={-1}
                onBlur={() => !contextMenuPosition && clearActiveEvent()}
                style={{ outline: "none" }}
            >
                <span
                    className={classnames(
                        styles.event,
                        (event.seriesName !== null ||
                            event.seriesOptionCode !== null) &&
                            activeEvent &&
                            activeEvent.event === event.uuid
                            ? {
                                  activeEvent: true,
                              }
                            : null,
                        (event.seriesName !== null ||
                            event.seriesOptionCode !== null) &&
                            activeEvent &&
                            activeEvent.series === event.seriesUuid
                            ? {
                                  activeSeries: true,
                              }
                            : null,
                        {
                            collapsed: collapsedVenues.find(
                                venueItem => venueItem === venue.code,
                            ),
                        },
                        event &&
                            ["CANCELED", "OPTION_CONFIRMED_CANCELED"].includes(
                                event.status,
                            ) &&
                            isShowCanceled === true && {
                                strikethrough: true,
                            },
                        event &&
                            ["CANCELED", "OPTION_CONFIRMED_CANCELED"].includes(
                                event.status,
                            ) &&
                            !isShowCanceled && {
                                hidden: true,
                            },
                    )}
                    onClick={() => {
                        toggleActiveEvent(event.uuid, event.seriesUuid);
                    }}
                    onDoubleClick={() => {
                        {
                            event &&
                                ["PUBLIC", "PRIVATE"].includes(event.status) &&
                                event.uuid &&
                                onDoubleClickEvent(event.uuid);
                        }
                    }}
                >
                    <div
                        className={
                            (styles.nameIcon,
                            (isMobile || isTablet) &&
                            (event.seriesName !== null ||
                                event.seriesOptionCode !== null) &&
                            activeEvent &&
                            activeEvent.event === event.uuid
                                ? styles.nameIconMobile
                                : styles.nameIcon)
                        }
                    >
                        <CalendarIcon
                            status={this.getStatus(event)}
                            color={getColor(venue.code)}
                        />
                        {!collapsedVenues.find(
                            venueItem => venueItem === venue.code,
                        ) && (
                            <Typography
                                color="inherit"
                                className={styles.eventName}
                            >
                                {this.label}
                            </Typography>
                        )}
                    </div>
                    {(isMobile || isTablet) &&
                        (event.seriesName !== null ||
                            event.seriesOptionCode !== null) &&
                        activeEvent &&
                        activeEvent.event === event.uuid && (
                            <span
                                onClick={e => {
                                    e.preventDefault();
                                    activateEvent(event.uuid, event.seriesUuid);
                                    this.setState({
                                        contextMenuPosition: {
                                            mouseX: e.clientX,
                                            mouseY: e.clientY,
                                        },
                                    });
                                }}
                            >
                                <MoreVertIcon className={styles.icon} />
                            </span>
                        )}
                </span>
                {contextMenuPosition &&
                    canUpdate &&
                    event &&
                    (event.seriesName !== null ||
                        event.seriesOptionCode !== null) && (
                        <ContextMenu
                            contextMenuPosition={contextMenuPosition}
                            onClose={() =>
                                this.setState({ contextMenuPosition: null })
                            }
                        >
                            {event.seriesHasCreatedOptions && (
                                <MenuItem
                                    id={`${id}-${event.uuid}-confirm-options`}
                                    onClick={() =>
                                        onConfirmOptions(event.seriesUuid)
                                    }
                                >
                                    <Typography>
                                        {translate("Confirm options")}
                                    </Typography>
                                </MenuItem>
                            )}
                            {event.status &&
                                event.status === "OPTION_CREATED" && [
                                    <Divider />,
                                    <MenuItem
                                        id={`${id}-${event.uuid}-edit-option`}
                                        onClick={() => onEditOption(event.uuid)}
                                    >
                                        <Typography>
                                            {translate("Edit Option")}
                                        </Typography>
                                    </MenuItem>,
                                    <MenuItem
                                        id={`${id}-${event.uuid}-delete-option`}
                                        onClick={() =>
                                            onCancelOption(event.uuid)
                                        }
                                    >
                                        <Typography>
                                            {translate("Delete Option")}
                                        </Typography>
                                    </MenuItem>,
                                ]}
                            {event &&
                                event.status &&
                                (event.status === "PUBLIC" ||
                                    event.status === "PRIVATE") && [
                                    <Divider />,
                                    <MenuItem
                                        id={`${id}-${event.uuid}-edit-event`}
                                        onClick={() => onEditEvent(event.uuid)}
                                    >
                                        <Typography>
                                            {translate("Edit Event")}
                                        </Typography>
                                    </MenuItem>,
                                    <MenuItem
                                        id={`${id}-${event.uuid}-cancel-event`}
                                        onClick={() =>
                                            onCancelEvent(event.uuid)
                                        }
                                    >
                                        <Typography>
                                            {translate("Cancel Event")}
                                        </Typography>
                                    </MenuItem>,
                                ]}
                            {event && event.status && this.isAllowedToMove && (
                                <MenuItem
                                    id={`${id}-${event.uuid}-move-event`}
                                    onClick={() => {
                                        onMoveEvent(event.uuid);
                                    }}
                                >
                                    <Typography>
                                        {translate("Move Event")}
                                    </Typography>
                                </MenuItem>
                            )}
                            {event && event.seriesHasConfirmedOptions && (
                                <MenuItem
                                    id={`${id}-${event.uuid}-define-events`}
                                    onClick={() =>
                                        onDefineEvents(event.seriesUuid)
                                    }
                                >
                                    <Typography>
                                        {translate("Define Events")}
                                    </Typography>
                                </MenuItem>
                            )}

                            {event &&
                                event.seriesUuid && [
                                    <Divider />,
                                    <MenuItem
                                        id={`${id}-${event.uuid}-edit-series`}
                                        onClick={() =>
                                            onEditSeries(event.seriesUuid)
                                        }
                                    >
                                        <Typography>
                                            {translate("Edit Series")}
                                        </Typography>
                                    </MenuItem>,
                                    <Divider />,
                                    <MenuItem
                                        id={`${id}-${event.uuid}-add-option-series`}
                                        onClick={() =>
                                            onAddOptionToSeries(
                                                event.seriesUuid,
                                                id,
                                            )
                                        }
                                    >
                                        <Typography>
                                            {translate("Add Option To Series")}
                                        </Typography>
                                    </MenuItem>,
                                    <MenuItem
                                        id={`${id}-${event.uuid}-add-event-series`}
                                        onClick={() =>
                                            onAddEventToSeries(
                                                event.seriesUuid,
                                                id,
                                            )
                                        }
                                    >
                                        <Typography>
                                            {translate("Add Event To Series")}
                                        </Typography>
                                    </MenuItem>,
                                ]}
                            {event &&
                                event.status &&
                                event.status === "OPTION_CONFIRMED" && [
                                    <Divider />,
                                    <MenuItem
                                        id={`${id}-${event.uuid}-edit-confirmed-option`}
                                        onClick={() =>
                                            onEditConfirmedOption(event.uuid)
                                        }
                                    >
                                        <Typography>
                                            {translate("Edit Confirmed Option")}
                                        </Typography>
                                    </MenuItem>,
                                    <MenuItem
                                        id={`${id}-${event.uuid}-delete-confirmed-option`}
                                        onClick={() =>
                                            onCancelConfirmedOption(event.uuid)
                                        }
                                    >
                                        <Typography>
                                            {translate(
                                                "Delete Confirmed Option",
                                            )}
                                        </Typography>
                                    </MenuItem>,
                                ]}
                            {event &&
                                event.status &&
                                event.status === "LIMITED_AVAILABILITY" && [
                                    <Divider />,
                                    <MenuItem
                                        id={`${id}-${event.uuid}-edit-confirmed-option-limited`}
                                        onClick={() =>
                                            onEditConfirmedOption(event.uuid)
                                        }
                                    >
                                        <Typography>
                                            {translate(
                                                "Edit Confirmed Option (Limited)",
                                            )}
                                        </Typography>
                                    </MenuItem>,
                                    <MenuItem
                                        id={`${id}-${event.uuid}-cancel-confirmed-option-limited`}
                                        onClick={() =>
                                            onCancelConfirmedOption(event.uuid)
                                        }
                                    >
                                        <Typography>
                                            {translate(
                                                "Delete Confirmed Option (Limited)",
                                            )}
                                        </Typography>
                                    </MenuItem>,
                                ]}
                            <Divider />
                            <MenuItem
                                id={`${id}-${event.uuid}-history`}
                                onClick={() => onShowHistory(event.uuid)}
                            >
                                <Typography>
                                    {translate("Show History")}
                                </Typography>
                            </MenuItem>
                        </ContextMenu>
                    )}
            </div>
        );
    }
}
